import { InjectionToken } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export const apiBaseUrl = new InjectionToken('apiBaseUrl');
export class ApiService {
    constructor(http, _apiBaseUrl) {
        this.http = http;
        this._apiBaseUrl = _apiBaseUrl;
    }
    get(path, params = new HttpParams()) {
        return this.http.get(`${this._apiBaseUrl}${path}`, { params });
    }
    put(path, body = {}) {
        return this.http.put(`${this._apiBaseUrl}${path}`, body);
    }
    patch(path, body = {}) {
        return this.http.patch(`${this._apiBaseUrl}${path}`, body);
    }
    post(path, body = {}) {
        return this.http.post(`${this._apiBaseUrl}${path}`, body);
    }
    delete(path) {
        return this.http.delete(`${this._apiBaseUrl}${path}`);
    }
    bulkPost(path, body = {}, header = {}) {
        return this.http.post(`${this._apiBaseUrl}${path}`, body, header);
    }
    bulkPut(path, body = {}, header = {}) {
        return this.http.put(`${this._apiBaseUrl}${path}`, body, header);
    }
    getBlob(path, params = new HttpParams()) {
        return this.http.get(`${this._apiBaseUrl}${path}`, {
            responseType: 'blob'
        });
    }
    getBlobPost(path, body = {}) {
        return this.http.post(`${this._apiBaseUrl}${path}`, body, {
            observe: 'response',
            responseType: 'blob'
        });
    }
}
ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(apiBaseUrl)); }, token: ApiService, providedIn: "root" });
